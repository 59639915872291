import styled from 'styled-components'
import { space, layout, flexbox, position, border, color } from 'styled-system'

const Card = styled('div')(
  {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(33, 33, 33, 0.2)'
  },
  space,
  layout,
  flexbox,
  position,
  border,
  color
)

export default Card
