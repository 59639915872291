import React from 'react'

const Error = ({ width = '150', height = '116', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 150 116' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M62.0354 19.469L28.7786 95.8186C24.3757 105.92 32.4642 117.038 43.4096 115.922C66.5125 113.58 101.142 109.998 125.135 107.507C137.046 106.28 142.97 92.4225 135.66 82.916L84.693 16.6679C78.5339 8.66106 66.0796 10.2104 62.0354 19.469Z'
      fill='#FF8F35'
    />
    <path
      d='M69.085 42.0516L74.0444 72.4799C74.4526 74.9712 76.6416 76.756 79.1523 76.6568C81.799 76.5577 83.9139 74.3887 83.9386 71.7239L84.2849 40.6139C84.3096 38.2589 82.269 36.4122 79.9315 36.6725L72.4861 37.5153C70.2723 37.7756 68.7139 39.833 69.085 42.0516Z'
      fill='#FFBF74'
    />
    <path
      d='M88.3167 91.8896C87.4015 95.9302 83.3944 98.471 79.3625 97.5662C75.3306 96.649 72.7952 92.6332 73.6981 88.5927C74.6133 84.5521 78.6204 82.0112 82.6523 82.916C86.6966 83.8332 89.2319 87.849 88.3167 91.8896Z'
      fill='#FFBF74'
    />
    <path
      d='M26.6511 1.41031L1.89092 24.1045C-1.38652 27.1039 -0.223958 32.5327 3.99343 33.9209C12.8982 36.8583 26.2677 41.2212 35.5187 44.2578C40.1195 45.7575 44.5719 41.6302 43.4217 36.9203L35.4693 4.09989C34.5046 0.13368 29.6564 -1.34126 26.6511 1.41031Z'
      fill='#FF8F35'
    />
    <path
      d='M25.5505 10.892L22.3968 22.927C22.1371 23.9062 22.6565 24.9349 23.5964 25.3067C24.5859 25.7034 25.7237 25.2448 26.1689 24.278L31.3881 12.8627C31.7839 11.9951 31.3386 10.9788 30.4234 10.6937L27.5541 9.78892C26.7007 9.51624 25.7855 10.0244 25.5505 10.892Z'
      fill='#FFBF74'
    />
    <path
      d='M24.4993 32.4459C23.4975 33.7845 21.6052 34.0695 20.2695 33.0656C18.9338 32.0616 18.6493 30.1653 19.6511 28.8267C20.6529 27.4881 22.5452 27.203 23.8809 28.207C25.2166 29.1985 25.501 31.1073 24.4993 32.4459Z'
      fill='#FFBF74'
    />
    <path
      d='M121.684 8.45031L116.069 34.1192C115.327 37.5152 118.506 40.4527 121.82 39.4364C128.82 37.2921 139.321 34.0572 146.593 31.8138C150.204 30.6983 151.194 26.0504 148.349 23.5591L128.524 6.19452C126.137 4.08747 122.364 5.33931 121.684 8.45031Z'
      fill='#FF8F35'
    />
    <path
      d='M125.246 15.0069L128.647 24.1168C128.919 24.8605 129.711 25.2819 130.49 25.096C131.306 24.8977 131.826 24.1044 131.665 23.274L129.859 13.6188C129.723 12.8875 128.981 12.4413 128.276 12.6644L126.025 13.3833C125.357 13.6064 124.999 14.3376 125.246 15.0069Z'
      fill='#FFBF74'
    />
    <path
      d='M134.25 29.2358C134.213 30.5496 133.137 31.5784 131.826 31.5412C130.527 31.504 129.488 30.4257 129.525 29.1119C129.562 27.7981 130.638 26.7693 131.949 26.8065C133.248 26.8437 134.287 27.9344 134.25 29.2358Z'
      fill='#FFBF74'
    />
  </svg>
)

export default Error
