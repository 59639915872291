import React, { useState } from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import styled from 'styled-components'

import Hamburger from './Hamburguer'

import useScroll from 'helpers/useScroll'

import otterwiseLogo from 'images/otterwiseLogo.svg'

const Navbar = () => {
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false)
  const { scroll } = useScroll()

  const handleMenuMobile = () => setMenuMobileOpen(!isMenuMobileOpen)

  const handleClickMenu = id => {
    setMenuMobileOpen(false)
    return scrollTo(id)
  }

  return (
    <Base scroll={scroll}>
      <Content scroll={scroll} tabIndex='0'>
        <img onClick={() => handleClickMenu('#top')} src={otterwiseLogo} />
        <List scroll={scroll} openMenu={isMenuMobileOpen}>
          <li>
            <Link to='/'>HOME</Link>
          </li>
          <li>
            <Link to='/contact'>CONTATO</Link>
          </li>
          <li>
            <a href='https://discord.gg/dMq26dxYN4' target='_blank'>
              COMUNIDADE
            </a>
          </li>
        </List>
        <MenuContent>
          <Hamburger handleMenuMobile={handleMenuMobile} isMenuMobileOpen={isMenuMobileOpen} />
        </MenuContent>
      </Content>
    </Base>
  )
}

const Base = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 0.5s ease-in-out;
  background-color: ${({ scroll, theme }) => (scroll > 10 ? theme.colors.primary[600] : 'transparent')};
  & a {
    text-decoration: none;
    color: #fff;
  }
`
const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1085px;
  padding: 15px 0;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary[600]};
  @media only screen and (min-width: 992px) {
    background-color: transparent;
    justify-content: space-between;
    padding: 20px 45px;
  }
`

const List = styled.ul`
  display: block;
  list-style: none;
  position: absolute;
  ${({ openMenu }) => (openMenu ? 'padding: 115px 16px' : '')};
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ openMenu }) => (openMenu ? '100vh' : '0')};
  background-color: ${({ theme }) => theme.colors.primary[600]};
  transition: all 1s ease-in-out;
  overflow: hidden;
  li {
    animation: ${({ openMenu }) =>
      openMenu ? 'fadeIn 1.5s forwards ease-in-out' : 'fadeOut 0.5s forwards ease-in-out'};
    font-size: 24px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    text-align: center;
  }
  li:not(:first-child) {
    margin-top: 24px;
  }
  @media only screen and (min-width: 992px) {
    display: flex;
    position: initial;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    background-color: transparent;
    width: 350px;
    height: 100%;
    li {
      animation: none;
      text-align: left;
      font-size: 12px;
      color: white;
      cursor: pointer;
    }
    li:not(:first-child) {
      margin-top: 0;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-30px);
    }
  }
`
const MenuContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export default Navbar
