import React from 'react'

import { Row, Column, Text } from 'components'

import { introduction } from 'data/data'

const Introduction = () => (
  <Row id='otterwise' pl={16} pr={16} flexDirection='column' alignItems='center' backgroundColor='secondary.50'>
    <Column mt={56}>
      <Text textAlign='center' fontSize='12px' lineHeight='16px' fontWeight={700} color='primary.600'>
        {introduction.title}
      </Text>
    </Column>
    <Column mt={16} maxWidth={588}>
      <Text as='h1' textAlign='center' fontSize='24px' lineHeight='40px' fontWeight={600} color='primary.500'>
        {introduction.subtitle}
      </Text>
    </Column>
    <Column maxWidth={944} mt={16}>
      <Text textAlign='justify' fontSize='16px' lineHeight='24px' color='gray.800'>
        {introduction.text}
      </Text>
    </Column>
  </Row>
)

export default Introduction
