import React from 'react'
import styled from 'styled-components'

import { TimelineCard, Row, Column, Text } from 'components'

import LineInTime from 'images/Timeline.svg'

import useScroll from 'helpers/useScroll'

import { proposal } from 'data/data'

const Timeline = () => {
  const scroll = useScroll()

  return (
    <Row p='56px 0 36px' justifyContent='center' flexDirection='column' backgroundColor={['gray.150', 'white']}>
      <Column width='100%'>
        <Text as='h1' textAlign='center' fontWeight='600' color='primary.500' fontSize='24px' lineHeight='40px'>
          Metodologia
        </Text>
      </Column>
      <Row mt={[100, 100]} justifyContent='center' alignItems='center'>
        <TimelineContent>
          <Line
            backgroundImage={LineInTime}
            height={String(scroll.scroll - (proposal[0].height + 50))}
            maxHeight={proposal[proposal.length - 1].height - proposal[0].height}
          />
          <ul>
            {proposal.map((item, index) => (
              <Item fixedHeight={item.height} scroll={scroll.scroll} key={index} icon={item.icon}>
                <TimelineCard data={item} />
              </Item>
            ))}
          </ul>
        </TimelineContent>
      </Row>
    </Row>
  )
}

const TimelineContent = styled.div`
  width: 100%;
  height: auto;
  max-width: 800px;
  margin: -100px auto 0;
  position: relative;
  ul {
    list-style: none;
  }
  @media only screen and (min-width: 992px) {
    margin: 0;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      left: 50%;
      background-color: transparent;
      transform: translateX(-50%);
    }
  }
`
const Item = styled.li`
  padding: 20px;

  text-align: center;

  @media only screen and (min-width: 992px) {
    width: 50%;
    position: relative;
    :not(:first-child) {
      margin-top: 0px;
    }
    ::after {
      content: ${({ icon }) => `url(${icon})`};
      position: absolute;
      top: 0;
    }
    :nth-child(odd) {
      float: left;
      clear: right;
      transform: translateX(-30px);
      text-align: right;
      padding-right: 40px;
      opacity: 0;
      animation: ${({ fixedHeight, scroll = 0 }) =>
        scroll > fixedHeight ? 'inLeft 0.5s forwards ease-in-out' : 'outLeft 1s forwards ease-in-out'};
    }
    :nth-child(even) {
      margin-top: -100px;
      float: right;
      clear: left;
      transform: translateX(30px);
      text-align: left;
      padding-left: 40px;
      animation: ${({ scroll = 0, fixedHeight }) =>
        scroll > fixedHeight ? 'inRight 0.5s forwards ease-in-out' : 'outRight 1s forwards ease-in-out'};
    }
    :nth-child(odd):after {
      transform: translate(50%, -50%);
      right: -30px;
    }
    :nth-child(even):after {
      transform: translate(-50%, -50%);
      left: -30px;
    }
  }

  @keyframes inLeft {
    from {
      transform: translateX(-80px);
      opacity: 0;
    }
    to {
      transform: translateX(-30px);
      opacity: 1;
    }
  }
  @keyframes outLeft {
    from {
      transform: translateX(-30px);
      opacity: 1;
    }
    to {
      transform: translateX(-80px);
      opacity: 0;
    }
  }
  @keyframes inRight {
    from {
      transform: translateX(80px);
      opacity: 0;
    }
    to {
      transform: translateX(30px);
      opacity: 1;
    }
  }
  @keyframes outRight {
    from {
      transform: translateX(30px);
      opacity: 1;
    }
    to {
      transform: translateX(80px);
      opacity: 0;
    }
  }
  @keyframes delay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes grow {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
`
const Line = styled.div`
  display: none;
  position: absolute;
  width: 85px;
  height: ${({ height }) => `${height}px`};
  max-height: calc(100% - 50px);
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-10%);
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: repeat-y;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`

export default Timeline
