import React from 'react'
import styled from 'styled-components'

import { Row, Text } from 'components'

const Schedule = ({ data, helper }) => (
  <Row width='100%' alignItems='center' flexDirection='column'>
    <ScheduleContent>
      {data.map((item, index) => (
        <StyledBox key={index}>
          <Text as='h2' fontSize='16px' textAlign='center' lineHeight='24px' fontWeight='600' color='white'>
            {item.title}
          </Text>
          <Text lineHeight='24px' textAlign='center' color='white'>
            {item.description}
          </Text>
        </StyledBox>
      ))}
    </ScheduleContent>
    {helper && (
      <Row flexDirection='column' width='100%' maxWidth='950px'>
        <Text mt='10px' fontSize='12px' textAlign='left' color='#424242'>
          * Condições de pagamento:
        </Text>
        <Text fontSize='12px' textAlign='left' color='#424242'>
          Até 12x sem juros no cartão de crédito
        </Text>
        <Text fontSize='12px' textAlign='left' color='#424242'>
          15% de desconto à vista (boleto bancário)
        </Text>
        <Text fontSize='12px' textAlign='left' color='#424242'>
          Financiamento estudantil em até 24x
        </Text>
      </Row>
    )}
  </Row>
)

const ScheduleContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: hidden;
  overflow-x: scroll;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.complement[700]};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.25);
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    max-width: 996px;
  }

  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 8px;
    overflow: hidden;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.complement[400]};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.complement[300]};
  }
`

const StyledBox = styled.div`
  width: 100%;
  padding: 16px 2px;

  &:nth-child(n + 2) {
    border-top: ${({ theme }) => `1px solid ${theme.colors.complement[200]}`};
  }
  @media only screen and (min-width: 992px) {
    &:nth-child(n + 2) {
      border-top: none;
    }
    width: 45%;
    &:not(:first-child) {
      border-left: ${({ theme }) => `1px solid ${theme.colors.complement[200]}`};
    }
  }
`

export default Schedule
