import React, { useMemo, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import TextField from './TextField'

import { removeFormatter, formatPhoneRegex } from 'helpers/formatters'

const PHONE = 'phone'

const MaskedInput = ({ inputProps, ...props }) => {
  const { name, ref: register, onChange, mask, value, placeholder } = inputProps

  const inputRef = useRef(null)

  const setValue = useCallback(
    value => {
      inputRef.current.value = value
    },
    [inputRef]
  )

  const maskedRegex = useMemo(() => {
    switch (mask) {
      case PHONE:
        return formatPhoneRegex
      default:
        return value => value
    }
  }, [mask])

  useEffect(() => {
    const initializeValue = () => {
      if (inputRef.current && maskedRegex) {
        if (!value) {
          return setValue(value || '')
        }

        return setValue(maskedRegex(removeFormatter(value)))
      }
    }

    initializeValue()
  }, [inputRef, maskedRegex, value])

  useEffect(() => {
    if (register) {
      register({ name })
    }
  }, [register])

  const handleChange = useCallback(
    ({ target: { value: newValue, name } }) => {
      const parsedValue = maskedRegex(removeFormatter(newValue))

      if (!parsedValue && newValue) {
        return setValue(value || '')
      }

      const nextValue = parsedValue || ''
      setValue(nextValue)

      return onChange({ target: { value: nextValue, name } })
    },
    [maskedRegex, onChange, setValue, value]
  )

  return <TextField {...props} inputProps={{ ref: inputRef, onChange: handleChange, name: name, placeholder }} />
}

MaskedInput.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  onChange: PropTypes.func,
  mask: PropTypes.oneOf([PHONE]),
  value: PropTypes.string
}

export default MaskedInput
