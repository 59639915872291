import React from 'react'
import styled from 'styled-components'

import { Text, Button } from 'components'

import HeaderImg from 'images/header-img.png'
import HeaderMobile from 'images/header-mobile.png'
import { Discord } from 'images'

import useWindowSize from 'helpers/useWindowSize'

const Header = ({ noImage, title, text }) => {
  const { width } = useWindowSize()
  return (
    <Base id='top' noImage={noImage}>
      <Content noImage={noImage}>
        <Wrapper noImage={noImage}>
          <Text as='h1' color='white' fontWeight={600}>
            {title}
          </Text>
          <Text mt={16} lineHeight='24px' color='white'>
            {text}
          </Text>
          <a href='https://cursos.otterwise.co/inscricao-aulao-discord' target='_blank'>
            <Button
              mt={16}
              variant='secondary'
              width='315px'
              fontWeight={700}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Text mr='10px'>Aulas grátis toda semana</Text>
            </Button>
          </a>
        </Wrapper>
        {!noImage && (
          <WrapperImage>
            <Image src={width < 992 ? HeaderMobile : HeaderImg} />
          </WrapperImage>
        )}
      </Content>
    </Base>
  )
}

const Base = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary[500]};
  @media only screen and (min-width: 992px) {
    justify-content: center;
    flex-direction: row;
    height: ${({ noImage }) => (noImage ? '100%' : '556px')};
  }
`
const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0;
  margin: 0px;
  @media only screen and (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
    height: ${({ noImage }) => (noImage ? '100%' : '630px')};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ noImage }) => (noImage ? '70px 16px 40px' : '40px 16px 40px')};
  width: 100%;
  background: ${({ theme }) => theme.colors.primary[500]};
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  @media only screen and (min-width: 992px) {
    width: ${({ noImage }) => (noImage ? '100%' : '70%')};
    margin-top: 0 !important;
    justify-content: flex-start;
    align-items: ${({ noImage }) => (noImage ? 'center' : 'flex-start')};
    background-color: transparent;
    padding: ${({ noImage }) => (noImage ? '100px 0 50px 0' : '190px 22px 0 100px')};
    h1 {
      ${({ noImage }) => (noImage ? 'width: 100%;' : '')}
      ${({ noImage }) => (noImage ? 'max-width: 400px;' : '')}
      font-size: 24px;
      line-height: 30px !important;
      text-align: ${({ noImage }) => (noImage ? 'center' : 'left')} !important;
    }
    p {
      ${({ noImage }) => (noImage ? 'width: 100%;' : '')}
      ${({ noImage }) => (noImage ? 'max-width: 400px;' : '')}
      text-align: ${({ noImage }) => (noImage ? 'center' : 'left')} !important;
    }
  }
  @media only screen and (min-width: 1091px) {
    h1 {
      font-size: 32px;
      line-height: 48px !important;
    }
    p {
      font-size: 16px;
      line-height: 24px !important;
    }
  }
`

const WrapperImage = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (min-width: 992px) {
    height: 100%;
  }
`

const Image = styled.img`
  margin: 50px auto 10px;
  width: 100%;
  @media only screen and (min-width: 992px) {
    margin: 0;
    max-width: 720px;
    max-height: 680px;
    height: 100%;
  }
`

export default Header
