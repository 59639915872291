export const removeFormatter = string => string.toString().replace(/\(|\)| |-|\.|\/|,/g, '')

export const formatPhoneRegex = value => {
  const regex = /^(\d{0,2})(\d{0,5})(\d{0,4})$/g

  if (!regex.test(value)) {
    return null
  }

  return value.replace(regex, (regex, $1, $2, $3) =>
    [$1, $2, $3]
      .filter(group => !!group)
      .join('-')
      .replace(`${$1}-`, `(${$1}) `)
  )
}
