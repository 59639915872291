import EnsinoHibrido from 'images/EnsinoHibrido.svg'
import Aprendizagem from 'images/Aprendizagem.svg'
import CarreiraProfissional from 'images/CarreiraProfissional.svg'
import Mercado from 'images/MercadoTrabalho.svg'
import Profissional from 'images/ColocacaoProfissional.svg'
import SoftSkills from 'images/SoftSkills.svg'

import programImage from 'images/program.svg'

import gabrielRibeiro from 'images/testimonial/gabriel-ribeiro.png'
import moniele from 'images/testimonial/moniele.png'
import samantaTessmer from 'images/testimonial/samanta-tessmer.png'
import kaller from 'images/testimonial/kaller.png'
import blankUser from 'images/testimonial/user.png'

export const header = {
  title: 'Tecnologia, ruptura e autonomia',
  text:
    'Um espaço voltado ao desenvolvimento da autonomia e crescimento profissional, conduzido por uma aprendizagem focada na tecnologia com a finalidade da transformação digital.'
}

export const introduction = {
  title: 'A OTTERWISE',
  subtitle: 'Uma conexão entre empresas e profissionais capacitados na área da programação',
  text:
    'A Otterwise tem como proposta ser a ponte que liga pessoas às empresas, oportunizando um percurso formativo que vai desde a qualificação de profissionais com base na autonomia e na resolução de problemas, até o desenvolvimento de habilidades voltadas à carreira e (re)colocação profissional. Para isso se faz necessário o envolvimento de três núcleos.'
}

export const about = {
  profissional: {
    title: ' Profissionais',
    description: 'Pessoas dispostas a dedicar-se à busca pelo conhecimento com a finalidade da colocação profissional.'
  },
  otterwise: {
    title: 'Otterwise',
    description: 'Responsável por qualificar pessoas e identificar suas potencialidades frente ao mercado de trabalho.'
  },
  company: {
    title: 'Empresas',
    description:
      'Espaço responsável por contratar e oportunizar experiências e crescimento profissional tendo em vista a transformação digital.'
  }
}

export const course = {
  title: 'INSCRIÇÕES ABERTAS',
  subtitle: 'Formação front-end',
  text_1:
    'Essa formação te ajudará a começar ou aperfeiçoar a sua carreira como front-end através de um conteúdo totalmente alinhado com as reais necessidades do mercado.',
  schedule: [
    {
      title: 'Duração',
      description: '10 semanas'
    },
    {
      title: 'Carga Horária Total',
      description: '170h'
    },
    {
      title: 'Síncrono',
      description: '44h'
    },
    {
      title: 'Assíncrono',
      description: '40h'
    }
  ]
}

export const formationFrontend = {
  title: 'A FORMAÇÃO',
  subtitle: 'Qualificação com base no Desenvolvimento Pessoal e Profissional',
  text_1:
    'Somos uma empresa de educação com foco na preparação de pessoas para a inserção no mercado profissional. Nossa formação de front-end tem como finalidade uma formação voltada para o trabalho envolvendo as habilidades necessárias à prática profissional. Durante a nossa formação passaremos pela construção de conhecimentos técnicos de GIT, Javascript, HTML5, CSS3 e React, vinculando eles as práticas utilizadas por empresas de tecnologia e, tendo por base o desenvolvimento ágil.',
  text_2:
    'Além disso, trabalhamos as habilidades interpessoais (soft skills) associadas à técnica, incluindo nesse processo de formação uma abordagem sobre o mercado profissional que vai desde a elaboração do currículo até a compreensão dos processos de seleção e recrutamento. Assista o vídeo abaixo para entender o impacto que a formação causou na vida dos alunos.',
  text_3:
    'Após a sua pré-inscrição, vamos entrar em contato para explicar todos os detalhes importantes sobre a formação. Nosso compromisso é com o sucesso da sua educação, precisamos entender o seu perfil e apresentar os nossos objetivos, se estivermos alinhados, teremos o maior prazer de ajudar na sua formação.',
  video: 'https://www.youtube.com/embed/265I4ImJeqw',
  schedule: [
    {
      title: 'Duração',
      description: '10 semanas'
    },
    {
      title: 'Carga Horária Total',
      description: '170h'
    },
    {
      title: 'Síncrono',
      description: '44h'
    },
    {
      title: 'Assíncrono',
      description: '40h'
    },
    {
      title: 'Ínicio',
      description: '16/08'
    },
    {
      title: 'Investimento',
      description: '-'
    }
  ]
}

export const program = {
  title: 'PROGRAMAÇÃO',
  image: programImage
}
export const modules = {
  title: 'MÓDULOS',
  modules: [
    {
      title: 'Módulos Iniciais',
      subtitle: 'Uma base forte para o desenvolvimento sólido do conhecimento',
      delay: '0.5s',
      content: [
        {
          title: 'GIT',
          description:
            'Aplicações práticas da ferramenta utilizando o fluxo de trabalho adequado para o gerenciamento de código de todos os tipos de projetos.'
        },
        {
          title: 'Lógica de programação e estrutura de dados',
          description:
            'Revisão de conceitos de lógica programação e aplicação de estrutura de dados de forma prática utilizando Javascript.'
        },
        {
          title: 'JavaScript avançado e moderno',
          description:
            'Aprofundar conhecimentos sobre Javascript através de aplicações práticas para garantir a construção de uma carreira sólida e focada em retirar o que há de melhor das funcionalidades da linguagem.'
        }
      ]
    },
    {
      title: 'Módulos Web',
      subtitle: 'Desenvolvimento front-end',
      delay: '0.8s',
      content: [
        {
          title: 'Contexto Web',
          description: 'Entendimento sobre o ecossistema do desenvolvimento web.'
        },
        {
          title: 'HTML, CSS e JS',
          description: 'Entender os pilares do desenvolvimento front-end e como eles se relacionam.'
        },
        {
          title: 'React',
          description:
            'Desenvolvimento de interfaces utilizando todas as funcionalidades importantes da biblioteca, seguindo padrões de mercado e preparando o aluno para a realidade.'
        }
      ]
    },
    {
      title: 'Módulos paralelos',
      subtitle: 'Soft skills, métodos ágeis e realidade profissional',
      delay: '1.1s',
      content: [
        {
          title: 'Realidade profissional',
          description:
            'Compreensão do mercado profissional e das habilidades necessárias à carreira de profissionais da área de desenvolvimento de software.'
        },
        {
          title: 'Soft skills voltadas à prática',
          description:
            'Aplicação de técnicas e utilização de ferramentas para a autonomia profissional e para o gerenciamento de projetos, buscando o melhor desempenho da equipe de trabalho.'
        },
        {
          title: 'Carreira profissional e mercado de trabalho',
          description:
            'Conhecimento a respeito das etapas que envolvem a inserção no mercado de trabalho, abordando ferramentas e técnicas utilizadas na atualidade pelo RH Estratégico.'
        }
      ]
    },
    {
      title: 'Atividades ao vivo durante toda a formação',
      subtitle: '',
      delay: '1.4s',
      content: [
        {
          title: 'Monitoria em grupos reduzidos',
          description:
            'Todos os dias durante a formação são disponibilizados horários para mentoria com nossos instrutores para garantir o avanço nas atividades de cada módulo.'
        },
        {
          title: 'Palestras',
          description:
            'Além dos professores e instrutores fixos também teremos palestras com especialistas do mercado trazendo temas relevantes para o desenvolvimento da formação dos alunos.'
        },
        {
          title: 'Aulas ao vivo todos os dias',
          description:
            'Durante toda a formação é possível fixar e aprofundar o conteúdo através de aulas ao vivo. Diariamente nossos professores ficam disponíveis para discutir o conteúdo da formação.'
        }
      ]
    }
  ]
}

export const proposal = [
  {
    title: 'Ensino Híbrido',
    skill: '(b-learning)',
    icon: EnsinoHibrido,
    description:
      'O Ensino Híbrido ou (blended learning) é um modalidade de ensino e aprendizagem que tem como proposta a junção de elementos da educação presencial e educação à distância. Como parte do ensino híbrido, utilizamos o flipped classroom como metodologia que tem como dinâmica a disponibilização de material prévio para estudo, e encontros presenciais (ou remotos) para realização de discussões e solução de dúvidas.',
    height: 700
  },
  {
    title: 'Aprendizagem Baseada em Problemas',
    skill: '(Problem-Based Learning)',
    icon: Aprendizagem,
    description:
      'Este método de aprendizagem promove engajamento dos estudantes e permite que vivenciem situações práticas reais, tais quais às experiências diárias do mercado de trabalho. Isso faz com que durante o período de curso sejam capacitados para resolver problemas desenvolvendo autonomia e, por consequência, ao se depararem com novas situações conseguirão enfrentá-las.',
    height: 1000
  },
  {
    title: 'Soft Skills',
    skill: '(Habilidades Interpessoais)',
    icon: SoftSkills,
    description:
      'O desenvolvimento de soft skills permite que se tenha profissionais versáteis que possuem autonomia, flexibilidade, inteligência emocional, boa comunicação e buscam o aprendizado constante. Estas são características fundamentais no novo mundo do trabalho. Possuir profissionais que desenvolvem as suas soft skills é sinônimo de ter profissionais inovadores e comprometidos com seus resultados e com o propósito da organização.',
    height: 1355
  },
  {
    title: 'Carreira Profissional',
    skill: '',
    icon: CarreiraProfissional,
    description:
      'O foco na Carreira Profissional proporciona um trabalho direcionado para escolhas e investimentos de forma assertiva levando em consideração aspectos técnicos, aspectos interpessoais e profissionais. Investir na Carreira Profissional significa olhar desde os aspectos diretos como estudos técnicos e desenvolvimento de soft skills até a investigação de empresas e processo de recrutamento e seleção.',
    height: 1680
  },
  {
    title: 'Mercado de Trabalho',
    skill: '',
    icon: Mercado,
    description:
      'Aprendizagens voltadas às habilidades e competências utilizadas no mercado de trabalho possibilitam que os estudantes consigam ter um melhor desempenho e aproveitamento de seus estudos e da aprendizagem significativa. Além disso, o foco no mercado de trabalho permite a facilidade de (re)colocação profissional. Logo, temos como premissa desenvolver em nossos estudantes habilidades técnicas e interpessoais voltadas às vagas e necessidades que atendam a relação dos estudantes com empresas.',
    height: 2050
  },
  {
    title: '(Re)Colocação Profissional',
    skill: '',
    icon: Profissional,
    description:
      'Estudantes ao ingressarem em um processo intensivo de busca por conhecimento tem como meta a colocação ou recolocação profissional. Desta forma, nosso objetivo está para além de somente formar profissionais qualificados tecnicamente. Sendo assim, nossa mola propulsora está em oportunizar que estudantes ocupem postos de trabalho em organizações que estejam de acordo com as suas características específicas.',
    height: 2300
  }
]

export const testimonials = {
  title: 'DEPOIMENTOS',
  subtitle: 'Veja o que algumas pessoas tem a dizer sobre nós',
  data: [
    {
      name: 'Gabriel Henrique Ribeiro e Silva',
      picture: gabrielRibeiro,
      role: 'student',
      message:
        'É uma experiência única e extremamente importante para quem tem interesse de se profissionalizar mas não sabe como, pois são apresentados diversos tópicos a respeito de carreira e soft skills...'
    },
    {
      name: 'Moniele Kunrath Santos',
      picture: moniele,
      role: 'student',
      message:
        '...no mercado de trabalho, além de ser requisito ter as famosas hard skills, é preciso desenvolver as mais variadas skills interpessoais, tais como empatia, comunicatividade e organização.'
    },
    {
      name: 'Brenda Samanta Ribeiro Tessmer',
      picture: samantaTessmer,
      role: 'student',
      message:
        'As aulas tanto da parte mais técnica quanto as de soft skills agregaram bastante para minha formação profissional e pessoal (principalmente os exercícios que me fizeram sair da zona de conforto).'
    },
    {
      name: 'Kaller Moraes Gonçalves',
      picture: kaller,
      role: 'student',
      message:
        'Acho que a mistura entre o Soft Skills e o Hard Skills e como eles trabalham juntos e são fundamentais me marcou bastante, junto com o feedback profissional dos professores.'
    },
    {
      name: 'Jonnhy Moraes Marques',
      picture: blankUser,
      role: 'student',
      message:
        'Sem dúvida nenhuma, hoje me sinto mais preparado para uma futura entrevista de emprego e na parte técnica consigo entender um código e procurar errors se caso o tenha, assim como, resolver possíveis desafios.'
    }
  ]
}
