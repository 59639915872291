const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    red: {
      100: '#D50000'
    },
    primary: {
      50: '#9E9E9E',
      100: '#CFCFDD',
      200: '#9D9EBB',
      300: '#666895',
      400: '#333571',
      500: '#00034E',
      600: '#000232',
      700: '#2A66FC'
    },
    secondary: {
      50: '#F3F9FF',
      100: '#E6F3FF',
      200: '#CDE6FE',
      300: '#9ACEFE',
      400: '#68B5FD',
      500: '#0366FC',
      600: '#0040D0'
    },
    complement: {
      50: '#F1FBF3',
      100: '#E3F6E8',
      200: '#C7ECD0',
      300: '#98DCA9',
      400: '#69CD81',
      500: '#29B74B',
      600: '#29B74B',
      700: '#076512',
      800: '#078316',
      900: '#21AC43',
      950: '#076512',
      960: '#078316'
    },
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      150: '#F3F3F7',
      200: '#EEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },
    orange: {
      50: '#FFD29D'
    }
  },
  breakpoints: ['768px']
}

export default theme
