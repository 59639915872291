import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { TextField, TextFieldMasked, Button, Row, Column, Text, ElementContainer } from 'components'

import { sendEmail } from 'services/contact'

import Close from 'images/Close'
import Plane from 'images/Plane'
import Error from 'images/Error'
import Zigzag from 'images/Zigzag'
import Ellipse from 'images/Ellipse'

const Contact = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const { register, errors, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      massage: ''
    }
  })

  const values = watch(['phone'])

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      const body = document.body
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
      await sendEmail(data)

      setOpen(true)
      reset()
    } catch {
      setError(true)
      setOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    const body = document.body
    body.style.height = '100%'
    body.style.overflowY = 'auto'
    setOpen(false)
  }

  return (
    <>
      <Row
        id='contact'
        p='56px 16px'
        backgroundColor='complement.700'
        alignItems='center'
        height='100vh'
        flexDirection='column'
        position='relative'
        overflow='hidden'
      >
        <Row position='relative' maxWidth='636px' zIndex={1}>
          <ElementContainer bottom='-30px' right={['-25px', '-25px']} zIndex={0}>
            <Ellipse stroke='#FFD29D' />
          </ElementContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ElementContainer top='20px' left={['-60px', '-12%']} zIndex={1}>
              <Zigzag stroke='#FFD29D' />
            </ElementContainer>
            <Row mt={20} flexDirection='column' maxWidth={588} width='100%'>
              <Column width='100%'>
                <TextField
                  error={errors && errors?.name}
                  color='white'
                  inputProps={{
                    placeholder: 'Informe seu nome completo',
                    ref: register({ required: true }),
                    name: 'name',
                    disabled: isLoading
                  }}
                  label='Nome completo'
                  placeholder='Informe seu nome completo'
                />
              </Column>
              <Row mt={32} flexDirection={['column', 'row']} width='100%'>
                <Column mr={[0, 12]} width='100%'>
                  <TextField
                    error={errors && errors?.email}
                    errorMsg={errors?.email?.type === 'pattern' ? 'Email inválido' : 'Preenchimento Obrigatório'}
                    color='white'
                    inputProps={{
                      placeholder: 'Informe seu e-mail',
                      ref: register({
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      }),
                      name: 'email',
                      disabled: isLoading
                    }}
                    label='E-mail'
                  />
                </Column>
                <Column ml={[0, 12]} mt={[32, 0]} width={['100%', 180]}>
                  <TextFieldMasked
                    error={errors && errors?.phone}
                    inputProps={{
                      placeholder: '(00) 00000-0000',
                      ref: register({ required: true }),
                      name: 'phone',
                      mask: 'phone',
                      onChange: e => setValue('phone', e.target.value, true),
                      value: values.phone,
                      disabled: isLoading
                    }}
                    label='Telefone'
                  />
                </Column>
              </Row>
              <Column mt={32} width='100%'>
                <TextField
                  error={errors && errors?.message}
                  area
                  label='Mensagem'
                  inputProps={{
                    placeholder: 'Digite aqui a sua mensagem',
                    ref: register({ required: true }),
                    name: 'message',
                    disabled: isLoading
                  }}
                  color='white'
                />
              </Column>
              <Row justifyContent='flex-end' mt={40}>
                <Button variant='primary' width={['100%', 180]} type='submit' disabled={isLoading}>
                  {isLoading ? 'Enviando...' : 'Enviar'}
                </Button>
              </Row>
            </Row>
            {open && (
              <Modal>
                <Background />
                <ModalContent open={open}>
                  <CloseButton type='button' onClick={() => onClose()}>
                    <Close />
                  </CloseButton>
                  <Column alignItems='center' m='32px 0'>
                    {error ? <Error /> : <Plane />}
                  </Column>
                  <Text textAlign='center' fontSize='16px' lineHeight='24px' fontWeight={600} color='primary.500'>
                    {error ? 'Erro no envio da mensagem!' : 'Sua mensagem foi enviada com sucesso!'}
                  </Text>
                  <Text mt={16} maxWidth={388} textAlign='center' fontSize='16px' lineHeight='24px' color='gray.800'>
                    {error
                      ? 'Ocorreu um erro durante o envio da mensagem. Por favor, tente novamente.'
                      : 'Em breve, entraremos em contato com você.'}
                  </Text>
                </ModalContent>
              </Modal>
            )}
          </Form>
        </Row>
      </Row>
    </>
  )
}

const Form = styled.form`
  position: relative;
  max-width: 636px;
  width: 100%;
  z-index: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
  padding: 32px 16px;
  @media only screen and (min-width: 768px) {
    padding: 32px;
  }
`

const Modal = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  background-color: transparent;
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 75%;
  overflow: hidden;
  background-color: #000;
`

const ModalContent = styled.div`
  max-width: 486px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 21px 21px 64px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
  animation: ${({ open }) => (open ? 'slideDown 0.5s forwards ease-in-out' : 'slideUp 0.5s forwards ease-in-out')};
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-30px);
    }
  }
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const CloseButton = styled.button`
  display: block;
  margin: 0 0 0 auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export default Contact
