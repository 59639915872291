import React from 'react'
import styled from 'styled-components'

import { Row, Column, Text } from 'components'

const TimelineCard = ({ data }) => (
  <Row justifyContent='flex-end'>
    <Column>
      <Card>
        <img src={data.icon} />
        <Text as='h2' color='primary.500' fontSize='16px' lineHeight='24px' fontWeight={600}>
          {data.title}
        </Text>
        <Text mt='4px' color='primary.400' fontSize='12px' lineHeight='16px'>
          {data.skill}
        </Text>
        <Text mt='16px' color='gray.800' fontSize='16px' lineHeight='24px'>
          {data.description}
        </Text>
      </Card>
    </Column>
  </Row>
)

const Card = styled.div`
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
  background-color: white;
  @media only screen and (min-width: 992px) {
    box-shadow: none;
    img {
      display: none;
    }
  }
`

export default TimelineCard
