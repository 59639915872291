import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import thumbnail from 'images/thumbnail.png'

const SEO = ({ description, lang, meta, title, image, path = '', keywords }) => {
  const { site } = useStaticQuery(query)

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || `${site.siteMetadata.siteURL}${thumbnail}`

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: 'keywords',
          content: keywords
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:image',
          content: metaImage
        },
        {
          property: 'og:url',
          content: 'https://otterwise.com.br'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:image',
          content: metaImage
        },
        {
          name: 'twitter:site',
          content: `${site.siteMetadata.siteURL}${path}`
        }
      ].concat(meta)}
    />
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteURL
      }
    }
  }
`

SEO.defaultProps = {
  lang: 'pt-br',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
