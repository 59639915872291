import React from 'react'

const Plane = ({ width = '146', height = '116', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 146 116' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M28.5896 84.2955L34.5938 116L50.8967 93.1018L105.591 37.5118L56.225 59.1035C56.225 59.1035 32.377 74.0332 31.3929 75.7785C30.4088 77.5139 28.5896 84.2955 28.5896 84.2955Z'
      fill='#0040D0'
    />
    <path d='M0 73.4449L105.591 37.5219L28.5897 84.2956L0 73.4449Z' fill='#0366FC' />
    <path d='M72.6971 103.554L37.5962 90.4091L105.591 37.5219L72.6971 103.554Z' fill='#0366FC' />
    <path
      d='M70.8182 8.26083L78.6813 10.8538L78.1743 8.35058C78.1246 8.17107 78.0749 8.00153 78.0451 7.82201C78.0054 7.6425 77.9855 7.48293 77.9656 7.31339L77.9556 7.25355C77.5381 3.88265 79.7847 2.09747 83.2044 3.24437C84.6557 3.73305 86.0971 4.68049 87.3497 5.88724C87.131 1.23978 90.4611 -1.13381 95.4117 0.531694C100.909 2.38669 106.297 8.50018 107.45 14.2048L108.533 19.5703C108.712 20.438 109.329 21.1361 110.154 21.4253L114.796 23.021C116.844 23.7291 118.365 25.4644 118.792 27.5986C119.18 29.5035 117.361 31.1091 115.532 30.4908L72.4882 15.99C70.1621 15.2021 68.4324 13.2374 67.9453 10.8239C67.5974 9.14843 69.1879 7.72228 70.8182 8.26083Z'
      fill='#CDE6FE'
    />
    <path
      d='M110.472 47.0461L116.287 48.961L115.909 47.106C115.87 46.9763 115.84 46.8467 115.81 46.717C115.78 46.5874 115.77 46.4677 115.75 46.338L115.74 46.2981C115.432 43.8049 117.092 42.4884 119.617 43.3361C120.691 43.6952 121.755 44.4033 122.689 45.2909C122.53 41.8501 124.995 40.0949 128.654 41.3315C132.719 42.6979 136.706 47.2256 137.56 51.4443L138.366 55.4135C138.495 56.0518 138.952 56.5704 139.569 56.7898L142.998 57.9766C144.509 58.4952 145.642 59.7817 145.96 61.3575C146.249 62.7637 144.907 63.9505 143.545 63.4917L111.695 52.7607C109.975 52.1823 108.692 50.7262 108.335 48.941C108.096 47.7043 109.269 46.6572 110.472 47.0461Z'
      fill='#CDE6FE'
    />
  </svg>
)

export default Plane
