import React from 'react'

const Discord = ({ width, height, fill }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 400.000000 400.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0.000000,400.000000) scale(0.100000,-0.100000)' fill={fill} stroke='none'>
        <path
          d='M1855 3990 c-931 -65 -1691 -773 -1831 -1705 -21 -147 -21 -423 0
-570 132 -879 813 -1560 1691 -1690 146 -22 424 -22 570 0 878 130 1559 811
1691 1690 21 147 21 423 0 570 -120 797 -692 1439 -1466 1644 -203 54 -441 75
-655 61z m-164 -1115 l28 -62 53 8 c68 10 397 10 464 0 l51 -7 33 68 c28 57
37 67 54 63 131 -29 171 -39 231 -56 39 -12 115 -39 170 -61 l100 -39 57 -92
c131 -211 232 -465 283 -707 22 -105 28 -160 32 -342 3 -119 3 -232 -1 -251
-6 -31 -18 -43 -109 -102 -118 -78 -304 -170 -430 -214 -49 -17 -92 -31 -96
-31 -10 0 -90 120 -120 177 l-21 42 106 50 106 51 -23 19 c-22 18 -27 18 -59
4 -170 -73 -346 -113 -535 -120 -223 -8 -409 23 -611 102 -74 29 -85 31 -103
18 -12 -8 -21 -18 -21 -22 0 -9 171 -91 189 -91 21 0 10 -28 -45 -116 -80
-128 -69 -122 -161 -89 -125 44 -334 148 -446 221 l-105 68 -6 59 c-9 90 -7
252 4 368 31 306 136 611 305 889 62 101 70 110 118 130 144 63 442 146 471
132 5 -3 21 -33 37 -67z'
        />
        <path
          d='M1504 2131 c-179 -81 -179 -369 1 -452 114 -53 246 11 290 140 67
198 -113 393 -291 312z'
        />
        <path
          d='M2334 2132 c-50 -24 -107 -89 -122 -141 -18 -58 -14 -150 7 -196 71
-156 267 -181 368 -48 86 115 59 293 -58 370 -54 36 -137 42 -195 15z'
        />
      </g>
    </svg>
  )
}

export default Discord
