import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Row, Column, Button } from 'components'

const ContactArea = () => (
  <Row
    p='56px 16px'
    backgroundColor='complement.700'
    alignItems='center'
    flexDirection='column'
    position='relative'
    overflow='hidden'
  >
    <Column maxWidth={480}>
      <Link to='/contact/'>
        <Button variant='secondary' fontWeight={700}>
          FICOU COM DÚVIDA? ENTRE EM CONTATO
        </Button>
      </Link>
    </Column>
  </Row>
)

export default ContactArea
