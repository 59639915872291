import { useState, useLayoutEffect } from 'react'

const useWindowSize = () => {
  const [windowEvent, setWindowEvent] = useState({
    width: typeof window !== 'undefined' ? window?.innerWidth : undefined,
    height: typeof window !== 'undefined' ? window?.innerWidth : undefined
  })

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleEvent = () => {
      setWindowEvent({
        width: window?.innerWidth || undefined,
        height: window?.innerHeight || undefined
      })
    }

    window?.addEventListener('resize', handleEvent)

    handleEvent()

    return () => window?.removeEventListener('resize', handleEvent)
  }, [])

  return windowEvent
}

export default useWindowSize
