import styled from 'styled-components'
import { layout, space, position, color } from 'styled-system'

const ElementContainer = styled('div')(
  {
    position: 'absolute'
  },
  layout,
  space,
  position,
  color
)

export default ElementContainer
