import styled from 'styled-components'
import { color, typography, space, position, layout, variant, flexbox } from 'styled-system'

const Button = styled('button')(
  {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: '24px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out'
  },
  variant({
    variants: {
      primary: {
        bg: 'complement.950',
        '&:hover': {
          backgroundColor: 'complement.960'
        }
      },
      secondary: {
        bg: 'primary.700',
        '&:hover': {
          backgroundColor: 'primary.600'
        }
      }
    }
  }),
  color,
  typography,
  space,
  position,
  layout,
  flexbox
)

export default Button
