import React from 'react'
import styled from 'styled-components'

const Hamburger = ({ isMenuMobileOpen, handleMenuMobile }) => {
  return (
    <HamburgerMenu>
      <BurgerContainer onClick={handleMenuMobile} className={isMenuMobileOpen ? 'open' : ''}>
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </BurgerContainer>
    </HamburgerMenu>
  )
}

const HamburgerMenu = styled.div`
  display: block;
  padding: 20px 19px;
  @media screen and (min-width: 992px) {
    display: none;
  }
`
const BurgerLine = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: ${({ theme }) => theme.colors.white};
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`
const BurgerContainer = styled.div`
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  & span:nth-child(even) {
    left: 50%;
    border-radius: 0 50px 50px 0;
  }
  & span:nth-child(odd) {
    left: 1px;
    border-radius: 50px 0 0 50px;
  }
  & span:nth-child(1),
  & span:nth-child(2) {
    top: 0px;
  }
  & span:nth-child(3),
  & span:nth-child(4) {
    top: 7px;
  }
  & span:nth-child(5),
  & span:nth-child(6) {
    top: 14px;
  }
  &.open span:nth-child(1),
  &.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(2),
  &.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }
  &.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }
  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  &.open span:nth-child(5) {
    left: 5px;
    top: 12px;
  }
  &.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 12px;
  }
`
export default Hamburger
