import styled from 'styled-components'
import { space, layout, color, flexbox, position, border } from 'styled-system'

const Row = styled.div(
  {
    width: '100%',
    display: 'flex'
  },
  flexbox,
  space,
  layout,
  color,
  position,
  border
)

export default Row
