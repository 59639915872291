import React from 'react'

import { Row, Column } from 'components'

import { Instagram, LinkedIn, Discord } from 'images'

const Footer = () => (
  <Row justifyContent='center' backgroundColor='primary.600' p='16px 0'>
    <Column mr={12} width='auto'>
      <a href='https://www.instagram.com/otterwise.co/' target='_blank'>
        <Instagram />
      </a>
    </Column>
    <Column ml={12} width='auto'>
      <a href='https://www.linkedin.com/company/otterwise/' target='_blank'>
        <LinkedIn />
      </a>
    </Column>
    <Column ml={12} width='auto'>
      <a href='https://discord.gg/dMq26dxYN4' target='_blank'>
        <Discord width='18px' height='18px' fill='#fff' />
      </a>
    </Column>
  </Row>
)

export default Footer
