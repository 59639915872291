import styled from 'styled-components'
import { typography, layout, space, color } from 'styled-system'
import PropTypes from 'prop-types'

const Text = styled(props => props.as)(typography, color, layout, space)

Text.defaultProps = {
  as: 'p'
}

Text.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span', 'label', 'a'])
}

export default Text
