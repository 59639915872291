import React from 'react'
import styled from 'styled-components'
import Carousel from 'react-elastic-carousel'

import { Card, Row, Column, Text, ElementContainer } from 'components'

import useWindowSize from 'helpers/useWindowSize'

import Zigzag from 'images/Zigzag'
import Ellipse from 'images/Ellipse'

import { testimonials } from 'data/data'

const Testimonial = () => {
  const { width: isMobile } = useWindowSize()

  return (
    <Row
      id='testimonial'
      backgroundColor='secondary.50'
      p='0 0 56px'
      justifyContent='center'
      flexDirection='column'
      position='relative'
    >
      <Column width='100%' mt={90}>
        <Text as='h1' textAlign='center' fontSize='12px' lineHeight='16px' fontWeight={700} color='primary.600'>
          {testimonials.title}
        </Text>
      </Column>
      <Column width='100%' p='0 16px'>
        <Text textAlign='center' fontSize='24px' lineHeight='40px' fontWeight={600} color='primary.500'>
          {testimonials.subtitle}
        </Text>
      </Column>
      <Row justifyContent='center'>
        <Row justifyContent='center' mt={40} width='90%' maxWidth={992} position='relative' zIndex='2'>
          <ElementContainer top='50%' left={['10%', '25%']}>
            <Zigzag stroke='#FFD29D' />
          </ElementContainer>
          <ElementContainer top={['0', '5px']} right={['-13px', '-13px']}>
            <Zigzag stroke='#FFD29D' />
          </ElementContainer>
          <ElementContainer top='0' left={['10%', '20px']}>
            <Ellipse stroke='#21AC43' />
          </ElementContainer>
          <Content>
            <Carousel itemsToShow={isMobile < 768 ? 1 : 3} style={{ padding: '20px 0' }}>
              {testimonials.data.map((item, index) => (
                <Card
                  key={index}
                  width='316px'
                  backgroundColor='white'
                  p='24px 16px 16px'
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  ml='12px'
                  mr='12px'
                  mb='15px'
                  minHeight='370px'
                >
                  <Avatar size='huge' avatar={item.picture} />
                  <Text
                    as='h2'
                    mt='16px'
                    textAlign='center'
                    fontSize='16px'
                    lineHeight='24px'
                    fontWeight={600}
                    color='primary.500'
                  >
                    {item.name}
                  </Text>
                  <Text as='h2' textAlign='center' fontSize='12px' lineHeight='24px' color='primary.400'>
                    {item.role}
                  </Text>
                  <Text textAlign='center' fontSize='16px' lineHeight='24px' color='gray.800'>
                    {item.message}
                  </Text>
                </Card>
              ))}
            </Carousel>
          </Content>
        </Row>
      </Row>
    </Row>
  )
}

const Content = styled.div`
  width: 100%;
  button {
    min-width: 24px;
    width: 24px;
    font-size: 8px;
    height: 24px;
    line-height: 0;
    color: white !important;
    background-color: ${({ theme }) => `${theme.colors.primary[200]}  !important`};
  }
  .rec-dot {
    margin: 0 10px;
    min-width: 12px !important;
    width: 12px !important;
    height: 12px;
    box-shadow: none;
  }
  .rec-dot_active {
    background-color: ${({ theme }) => `${theme.colors.complement[500]}  !important`};
  }
`

const Avatar = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-image: ${({ avatar }) => `url(${avatar})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export default Testimonial
