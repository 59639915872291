import React from 'react'
import styled from 'styled-components'

import { Column, Text } from 'components'

import useScroll from 'helpers/useScroll'
import useWindowSize from 'helpers/useWindowSize'

import greenArrow from 'images/greenArrow.svg'

const ModulesCard = ({ title, subtitle, content, delay }) => {
  const { scroll } = useScroll()
  const { width } = useWindowSize()

  const isMobile = width <= 992 ? 0 : 555

  return (
    <Card delay={delay} scroll={scroll} isMobile={isMobile}>
      <CardHeader>
        <Text as='h1' textAlign='left' fontSize='16px' fontWeight={700} color='white'>
          {title}
        </Text>
        <Text mt={13} as='p' textAlign='left' fontSize='12px' fontWeight={400} color='white'>
          {subtitle}
        </Text>
      </CardHeader>
      <CardBody>
        {content.map(item => (
          <CardContent>
            <img src={greenArrow} alt='' />
            <Column ml='7px'>
              <Text as='h1' mt='-2px' textAlign='left' fontSize='14px' fontWeight={700} color='primary.600'>
                {item.title}
              </Text>
              <Text as='p' textAlign='left' fontSize='12px' fontWeight={400} color='primary.600'>
                {item.description}
              </Text>
            </Column>
          </CardContent>
        ))}
      </CardBody>
    </Card>
  )
}

const Card = styled.div`
  width: 100%;
  opacity: 0;
  margin-top: 15px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
  animation: ${({ scroll, isMobile }) =>
    scroll >= isMobile ? 'slide 0.5s forwards ease-in-out' : 'slideOut 0.5s forwards ease-in-out'};
  animation-delay: ${({ delay, scroll, isMobile }) => (scroll >= isMobile ? delay : 0)};
  @media only screen and (min-width: 992px) {
    max-width: 250px;
    margin-top: 0;
  }
  @keyframes slide {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(50px);
    }
  }
`
const CardHeader = styled.div`
  width: 100%;
  padding: 10px;
  min-height: 100px;
  background-color: #2a66fc;
  border-radius: 10px 10px 0 0;
`
const CardBody = styled.div`
  padding: 15px;
  & div + div {
    margin-top: 15px;
  }
`

const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

export default ModulesCard
