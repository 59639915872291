import React from 'react'
import styled from 'styled-components'

import { Card, Row, Column, Text } from 'components'

import useScroll from 'helpers/useScroll'
import useWindowSize from 'helpers/useWindowSize'

import { about } from 'data/data'

const About = () => {
  const { scroll } = useScroll()
  const { width } = useWindowSize()

  const isMobile = width <= 768 ? 150 : 0

  return (
    <Row justifyContent='center' backgroundColor='secondary.50'>
      <Row
        width='100%'
        maxWidth={992}
        p={['40px 16px 56px', '40px 0 56px']}
        justifyContent='center'
        flexDirection={['column', 'row']}
      >
        <Column mr={['12px']}>
          <AnimeCard scroll={scroll} isMobile={isMobile} delay='0.5s'>
            <Card maxWidth={['100%', '316px']} height={['auto', '167px']} backgroundColor='white' p='24px 16px 16px'>
              <Text as='h2' textAlign='center' fontSize={16} lineHeight='24px' fontWeight={600} color='primary.500'>
                {about.profissional.title}
              </Text>
              <Text as='p' mt='8px' textAlign='center' variant='sm' lineHeight='24px' fontSize='16px' color='gray.800'>
                {about.profissional.description}
              </Text>
            </Card>
          </AnimeCard>
        </Column>
        <Column mr={['0', '12px']} ml={['0', '12px']} mt={['16px', 0]}>
          <AnimeCard scroll={scroll} isMobile={isMobile} delay='0.8s'>
            <Card maxWidth={['100%', '316px']} height={['auto', '167px']} backgroundColor='white' p='24px 16px 16px'>
              <Text as='h2' textAlign='center' fontSize={16} lineHeight='24px' fontWeight={600} color='primary.500'>
                {about.otterwise.title}
              </Text>
              <Text
                mt='8px'
                textAlign='center'
                variant='sm'
                lineHeight={'24px !important'}
                fontSize={'16px !important'}
                color='gray.800'
              >
                {about.otterwise.description}
              </Text>
            </Card>
          </AnimeCard>
        </Column>
        <Column ml={['0', '12px']} mt={['16px', 0]}>
          <AnimeCard scroll={scroll} isMobile={isMobile} delay='1.2s'>
            <Card maxWidth={['100%', '316px']} height={['auto', '167px']} backgroundColor='white' p='24px 16px 16px'>
              <Text textAlign='center' fontSize={16} lineHeight='24px' fontWeight={600} color='primary.500'>
                {about.company.title}
              </Text>
              <Text
                mt='8px'
                textAlign='center'
                variant='sm'
                lineHeight={'24px !important'}
                fontSize={'16px !important'}
                color='gray.800'
              >
                {about.company.description}
              </Text>
            </Card>
          </AnimeCard>
        </Column>
      </Row>
    </Row>
  )
}

const AnimeCard = styled.div`
  width: 100%;
  opacity: 0;
  animation: ${({ scroll, isMobile }) =>
    scroll >= isMobile ? 'fadeIn 0.5s forwards ease-in-out' : 'fadeOut 0.5s forwards ease-in-out'};
  animation-delay: ${({ delay, scroll, isMobile }) => (scroll >= isMobile ? delay : 0)};
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-30px);
    }
  }
`

export default About
