import React from 'react'

const Zigzag = ({ width = '126', height = '30', stroke = '#9ACEFE', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 126 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M32.8475 26.6414C18.6627 26.6414 21.1055 3 3 3'
      stroke={stroke}
      strokeWidth='6'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M92.5425 26.6414C78.3577 26.6414 80.8006 3 62.6949 3'
      stroke={stroke}
      strokeWidth='6'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M32.8475 26.6414C47.0323 26.6414 44.5895 3 62.695 3'
      stroke={stroke}
      strokeWidth='6'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M92.5425 26.6414C106.727 26.6414 104.284 3 122.39 3'
      stroke={stroke}
      strokeWidth='6'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Zigzag
