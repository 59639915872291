import React from 'react'

const Line2 = ({ width = '373', height = '492', fill = '#0366FC', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 373 492' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 54.7566C14.1631 72.9126 22.4046 95.2683 22.2883 119.127L21.8444 210.161L21.8442 210.21V210.259C21.8442 234.664 29.4487 256.624 45.4632 271.874C61.6201 287.259 83.7617 293.354 107.72 290.878L107.941 290.855L108.162 290.827L163.438 283.868C179.661 282.882 189.291 287.915 195.105 294.163C201.427 300.959 205.36 311.526 205.36 324.395L205.36 411.034C205.36 456.471 243.924 491.479 289.18 491.479C334.436 491.479 373 456.471 373 411.034V352.287C373 341.242 364.046 332.287 353 332.287C341.954 332.287 333 341.242 333 352.287V411.034C333 432.361 314.419 451.479 289.18 451.479C263.941 451.479 245.36 432.361 245.36 411.034L245.36 324.395C245.36 303.882 239.113 282.741 224.39 266.917C209.222 250.614 187.09 242.157 160.176 243.996L159.607 244.035L159.042 244.106L103.401 251.111C88.8104 252.572 79.1053 248.675 73.047 242.906C66.8232 236.979 61.8533 226.594 61.8442 210.304V210.259L62.2878 119.322C62.5277 70.1094 37.8347 26.9876 0 0V54.7566Z'
      fill={fill}
    />
  </svg>
)

export default Line2
