import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { theme as styledTheme } from 'theme'

import './styles.css'

const Layout = ({ theme = styledTheme, children }) => (
  <>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)

Layout.propTypes = {
  theme: PropTypes.object
}

export default Layout
