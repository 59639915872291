import React from 'react'
import styled from 'styled-components'

import { Row, Column, Text, Button, ElementContainer } from 'components'

import Line from 'images/Line2'

const Program = ({ title, image }) => (
  <Row
    id='otterwise'
    pl={16}
    pr={16}
    flexDirection='column'
    alignItems='center'
    backgroundColor='white'
    position='relative'
  >
    <ElementContainer top='-100px' left={['-500px', '-200px']} zIndex='0'>
      <Line fill='#21AC43' />
    </ElementContainer>
    <Column mt={25}>
      <Text as='h1' textAlign='center' fontSize='12px' fontWeight={700} color='primary.600'>
        {title}
      </Text>
    </Column>
    <Column mt={23} zIndex='1'>
      <Image src={image} />
    </Column>
    <Column maxWidth={640} mt={28} mb={25} fontWeight={700}>
      <a
        href='https://cursos.otterwise.co/semana-profissao-front-end?utm_source=site&utm_medium=cta-inscricao&utm_campaign=formacao-frontend'
        target='_blank'
      >
        <Button variant='secondary' fontWeight={700}>
          CADASTRE-SE PARA GARANTIR SUA PRÉ INSCRIÇÃO NA NOSSA TURMA DE AGOSTO
        </Button>
      </a>
    </Column>
  </Row>
)

const Image = styled.img`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`

export default Program
