import React from 'react'
import styled from 'styled-components'

import { ModulesCard } from '../ModulesCard'
import { Row, Column, Text, ElementContainer } from 'components'

import Ellipse from 'images/Ellipse'

const Modules = ({ modules }) => (
  <Row
    id='otterwise'
    mt={35}
    pl={16}
    pr={16}
    pb={35}
    flexDirection='column'
    alignItems='center'
    backgroundColor='gray.150'
    position='relative'
  >
    <ElementContainer top='-25px' left='10%'>
      <Ellipse stroke='#21AC43' />
    </ElementContainer>
    <Column>
      <Text as='h1' pt={25} textAlign='center' fontSize='12px' fontWeight={700} color='primary.600'>
        {modules.title}
      </Text>
    </Column>
    <Row width='100%' maxWidth='1100px'>
      <Wrapper>
        {modules.modules.map((item, index) => (
          <ModulesCard title={item.title} subtitle={item.subtitle} content={item.content} delay={item.delay} />
        ))}
      </Wrapper>
    </Row>
  </Row>
)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 5px;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    margin-top: 25px;
  }
`

export default Modules
