import React from 'react'

const LinkedIn = ({ width = '16', height = '16', fill = 'white', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0)'>
      <path
        d='M23.994 24.0001V23.9991H24V15.1971C24 10.8911 23.073 7.5741 18.039 7.5741C15.619 7.5741 13.995 8.9021 13.332 10.1611H13.262V7.9761H8.48899V23.9991H13.459V16.0651C13.459 13.9761 13.855 11.9561 16.442 11.9561C18.991 11.9561 19.029 14.3401 19.029 16.1991V24.0001H23.994Z'
        fill={fill}
      />
      <path d='M0.395996 7.97693H5.372V23.9999H0.395996V7.97693Z' fill={fill} />
      <path
        d='M2.882 0C1.291 0 0 1.291 0 2.882C0 4.473 1.291 5.791 2.882 5.791C4.473 5.791 5.764 4.473 5.764 2.882C5.763 1.291 4.472 0 2.882 0V0Z'
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill={fill} />
      </clipPath>
    </defs>
  </svg>
)
export default LinkedIn
