import React from 'react'
import { Link } from 'gatsby'

import { Schedule, Row, Column, Text, Card, Button } from 'components'

import Ellipse from 'images/Ellipse'

const Course = () => (
  <Column
    p='0 16px 0'
    id='how-to-study'
    alignItems='center'
    position='relative'
    backgroundColor={['gray.150', 'white']}
    pb={'50px'}
  >
    <Text as='h1' textAlign='center' fontSize='22px' fontWeight={700} color='primary.600' pb='10px'>
      Aprenda como estudar programação do zero e de forma autônoma
    </Text>
    <Column justifyContent='center' alignItems='center'>
      <Text maxWidth={700} textAlign='center' fontSize='14px' fontWeight={600} color='primary.600' pb='15px'>
        Você vai aprender como organizar sua rotina de estudos, deixar a procrastinação de lado e ser eficiente no
        aprendizado.
      </Text>
      <Row justifyContent='center'>
        <Card
          flexDirection='column'
          maxWidth={['100%', '400px']}
          display='flex'
          justifyContent='center'
          alignItems='center'
          p={3}
        >
          <Text color='primary.600'>Por apenas:</Text>
          <Text fontSize={'44px'} color='primary.600' fontWeight='900'>
            R$ 497,00
          </Text>
          <Button
            display='flex'
            justifyContent='center'
            target='_blank'
            href='https://plataforma.otterwise.co/purchase?product_id=3548814'
            as={Link}
            variant='secondary'
            width='315px'
          >
            Quero essa solução!
          </Button>
        </Card>
      </Row>
    </Column>
  </Column>
)

export default Course
