import React from 'react'
import styled from 'styled-components'
import { color, border, typography, layout, flexbox, space } from 'styled-system'

const Input = styled('input')(
  {
    color: ({ theme }) => `1px solid ${theme.colors.gray[900]}`,
    fontWeight: 600,
    backgroundColor: 'transparent',
    border: ({ error, theme }) => `1px solid${error ? theme.colors.red[100] : theme.colors.primary[100]}`,
    borderRadius: '4px',
    padding: '8px',
    transition: 'all 0.5s ease-in-out',
    '&:focus': {
      border: ({ theme }) => `1px solid ${theme.colors.secondary[500]}`
    },
    '&::placeholder': {
      color: ({ theme }) => theme.colors.primary[50]
    }
  },
  color,
  border,
  typography
)
const TextArea = styled('textarea')(
  {
    display: 'block',
    boxSizing: 'border-box',
    color: ({ theme }) => `1px solid ${theme.colors.gray[900]}`,
    fontWeight: 600,
    backgroundColor: 'transparent',
    border: ({ error, theme }) => `1px solid ${error ? theme.colors.red[100] : theme.colors.primary[200]}`,
    borderRadius: '4px',
    padding: '8px',
    lineHeight: 1.5,
    resize: 'none',
    maxWidth: '100%',
    minHeight: '136px',
    height: 'auto',
    transition: 'all 0.5s ease-in-out',
    '&:focus': {
      border: ({ theme }) => `1px solid ${theme.colors.secondary[500]}`
    },
    '&::placeholder': {
      color: ({ theme }) => theme.colors.primary[50]
    }
  },
  color,
  border,
  typography
)
const Label = styled('label')(
  {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    color: ({ error, theme }) => (error ? theme.colors.red[100] : theme.colors.gray[900])
  },
  color,
  border,
  typography
)
const Span = styled('span')({ marginTop: '4px', fontSize: '12px', fontWeight: 600 }, color, border, typography)
const Base = styled('div')({ display: 'flex', flexDirection: 'column' }, color, layout, flexbox, space)

const TextField = ({
  area,
  label,
  labelProps,
  inputProps,
  error,
  errorMsg = 'Preenchimento Obrigatório',
  ...props
}) => (
  <Base {...props}>
    <Label error={error} {...labelProps}>
      {label}
    </Label>
    {!area ? <Input error={error} {...inputProps} /> : <TextArea error={error} {...inputProps} />}
    {!!error && <Span color={error ? 'red.100' : 'white'}>{errorMsg}</Span>}
  </Base>
)

export default TextField
