import { useState, useLayoutEffect } from 'react'

const useScroll = () => {
  const [windowEvent, setWindowEvent] = useState({
    scroll: undefined
  })

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleEvent = () => {
      setWindowEvent({
        scroll: parseInt(window?.pageYOffset)
      })
    }

    window?.addEventListener('scroll', handleEvent)

    handleEvent()

    return () => window?.removeEventListener('scroll', handleEvent)
  }, [])

  return windowEvent
}

export default useScroll
