import React from 'react'
import { Link } from 'gatsby'

import { Schedule, Row, Column, Text, ElementContainer, Button } from 'components'

import Ellipse from 'images/Ellipse'

const Course = ({ title, subtitle, text, schedule, video, helper = false }) => (
  <Row
    id='course'
    p='0 16px 0'
    flexDirection='column'
    alignItems='center'
    position='relative'
    backgroundColor={['gray.150', 'white']}
  >
    <ElementContainer top='-25px' right='10%'>
      <Ellipse />
    </ElementContainer>
    <Column width='100%' mt={56}>
      <Text as='h1' textAlign='center' fontSize='12px' fontWeight={700} color='primary.600'>
        {title}
      </Text>
    </Column>
    <Column mt={16} width='100%'>
      <Text textAlign='center' fontSize='22px' fontWeight={600} lineHeight='40px' color='primary.600'>
        {subtitle}
      </Text>
    </Column>
    <Column width='100%' maxWidth='998px' mt={16}>
      {text.text_1 && (
        <Text textAlign='justify' fontSize='16px' color='gray.800' lineHeight='24px'>
          {text.text_1}
        </Text>
      )}
      {text.text_2 && (
        <Text mt={16} textAlign='justify' fontSize='16px' color='gray.800' lineHeight='24px'>
          {text.text_2}
        </Text>
      )}
      {text.text_3 && (
        <Text mt={16} textAlign='justify' fontSize='16px' color='gray.800' lineHeight='24px'>
          {text.text_3}
        </Text>
      )}
    </Column>
    {video && (
      <Column width='100%' mt={28} maxWidth='480px' justifyContent='center' alignItems='center'>
        <iframe width='100%' frameBorder='0' allowFullScreen={true} height='315' src={video}></iframe>
      </Column>
    )}
    <Column width='100%' mt={40}>
      <Schedule data={schedule} helper={helper} />
    </Column>
  </Row>
)

export default Course
